<template>
  <er-dialog :class="`feed-template-action-dialog ${isHybridModeEnabled ? 'hybrid-type' : 'schedule-type'}`" :visible.sync="dialogVisible" top="10vh" :width="'var(--dialog-width)'"
    :close-on-press-escape="false" :close-on-click-modal="false" :before-close="handleCloseDialog"
    @open="handleOpenDialog">
    <template slot="header">
      <span class="material-icons-outlined"> calendar_month </span>
      <span>{{ action === "CREATE" ? $t("PM_create_new_set") : $t("edit-set") }}</span>
    </template>
    <component :is="getComponent" :location="getCurrUserLocation" :tabData="tabData" :feed-template="feedTemplate"
      @changedTemplate="handleChangedTemplate"></component>
    <span slot="footer">
      <el-row type="flex" justify="end">
        <er-button btnType="save" size="mini" :showLabel="true" :showIcon="true" :disabled="isReadOnly"
          :loading="loading" @click="submitFeedTemplateDetails">
          <slot>
            <p style="color: white">
              {{
                action === "CREATE"
                  ? `${$t("PM_create_new_set")}`
                  : `${$t("Comn_update") + " " + $t("set")}`
              }}
            </p>
          </slot>
        </er-button>
        <er-button btnType="cancel" size="mini" :showLabel="true" :disabled="isReadOnly"
          @click="handleCloseDialog"></er-button>
      </el-row>
    </span>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapActions, mapGetters } from "vuex";
import HybridFeedTemplate from "./hybridFeedTemplate.vue";
import ScheduleFeedTemplate from "./scheduleFeedTemplate.vue";
import featureToggleMixin from "../../mixins/featureToggleMixin";
export default {
  components: {
    HybridFeedTemplate,
    ScheduleFeedTemplate
  },
  emits: ["closeDialog"],
  mixins: [errorHandlerMixin, datesHandlerMixin, featureToggleMixin],
  props: {
    dialogVisible: {
      required: true,
    },
    action: {
      default: "CREATE",
      required: true,
    },
    feedTemplate: {
      default: function () {
        return {};
      },
      required: true,
    },
    tabData: {
      default: {},
    },
  },
  data: function () {
    return {
      loading: false,
      templateDialog: null,
      editedTemplate: null
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
    }),
    ...mapGetters("schedules", {
      getMapftIdFT: "getMapftIdFT",
      getArrFeedTemplates: "getArrFeedTemplates"
    }),
    existingFTs() {
      return this.getMapftIdFT
    },
    getFeedTemplateId: function () {
      return this.feedTemplate._id
    },
    setOfExistingFTTitlesWOCurrFTTitle: function () {
      const ftWOCurrFt = this.getArrFeedTemplates.filter(x => x._id !== this.getFeedTemplateId)
      return new Set(ftWOCurrFt.map(x => x.title))
    },
    templateType: function () {
      if (!this.feedTemplate) {
        return "NORMAL";
      }
      return this.feedTemplate.type;
    },
    isHybridModeEnabled() {
      return this.ftgm__isUserHasFeature("hybrid_mode")
    },
    getComponent() {
      return this.isHybridModeEnabled ? "HybridFeedTemplate" : "ScheduleFeedTemplate"
    },
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator",
    }),
    handleOpenDialog() {
      this.editedTemplate = this.$lodash.cloneDeep(this.feedTemplate)
    },
    async handleCloseDialog() {
      const resetAndEmit = () => {
        this.$emit("closeDialog", closeConfig);
      };
      const closeConfig = {
        field: this.action,
        formState: "NEW_FIELD",
        isFieldEditted: this.dialogVisible && !!this.editedTemplate,
      };
      if (closeConfig.isFieldEditted) {
        const message = this.$t("closing-new-field-changes-discard")
        try {
          await this.$confirm(message, "", {
            confirmButtonText: this.$t("ok"),
            cancelButtonText: this.$t("Comn_cancel"),
            cancelButtonClass: "er-button er-button--default",
            confirmButtonClass: "er-button er-button--confirm",
            type: "warning",
          });
          resetAndEmit();
        } catch (err) {
          console.log(err);
        }
      } else {
        resetAndEmit();
      }
    },
    isExisting() {
      const { title: newTitle } = this.editedTemplate;
      return this.setOfExistingFTTitlesWOCurrFTTitle.has(newTitle)
    },
    formatErrors(errorObj) {
      errorObj.forEach((error) => {
        if (error.message === "pls_enter_title_to_set") {
          error.message = this.$t("SET_alias_must_not_be_empty");
        } else if (error.message === "PM_sch_is_overlapping_msg") {
          const { position1, position2 } = error;
          error.message = this.$t("PM_sch_is_overlapping_msg", {
            position1,
            position2,
          });
        } else if (error.message === "total_should_sum_to_threshold") {
          error.message = this.$t("PM_tf_percentage_must_be_100");
        } else if (error.message === "PM_end_time_not_less_start_time") {
          const { message, position } = error;
          error.message = this.$t(message, { position });
        } else if (error.message === "PM_start_time_not_less_than_0015") {
          const { message, position } = error;
          error.message = this.$t(message, { position });
        } else if (error.message === "PM_end_time_less_than_end_time") {
          const { message, position, end_time } = error;
          error.message = this.$t(message, { position, end_time });
        } else if (error.message === "Comn_name_length_must_be_at_least_3_to_30_characters_long") {
          error.message = this.$t(error.message);
        }
      });
    },
    submitFeedTemplateDetails: async function () {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (this.isExisting()) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("SET_name_already_exists"),
              },
            ],
          };
        } else {
          const errorObj = this.editedTemplate.validate();
          if (errorObj.length > 0) {
            this.formatErrors(errorObj);
            throw {
              type: "FAIL_TO_SAVE",
              errors: errorObj,
            };
          }
          this.calculateDuration();
          this.loading = true;
          const payload = this.$lodash.cloneDeep(this.editedTemplate);
          if (payload.ponds.length > 0) {
            payload.ponds = payload.ponds.map(({ _id }) => _id);
          }
          const isEdit = this.action === "EDIT"
          const storeAction = isEdit ? "schedules/updateFeedTemplate" : "schedules/createFeedTemplate";
          await this.$store.dispatch(
            storeAction,
            payload
          );

          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: isEdit
              ? this.$t("Set_updated_successfully")
              : this.$t("Set_created_successfully"),
            duration: 5000,
            type: "success",
          });
          isEdit
            ? this.mixPanelEventGenerator({
              eventName:
                "Settings - Schedules - Schedules One Time Settings - Update set",
            })
            : this.mixPanelEventGenerator({
              eventName:
                "Settings - Schedules - Schedules One Time Settings - Create set",
            });
          this.ehm__unhandledErrorMessage = "";
        }
        this.$emit("closeDialog", { refetch: true });
      } catch (err) {
        const errors = [];
        if (err.errors && err.errors.length > 0) {
          this.ehm__errorMessages(err, true);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    calculateDuration() {
      this.editedTemplate.timeSlots = this.editedTemplate.timeSlots.map(
        (timeSlot) => {
          if (timeSlot.s_time && timeSlot.e_time) {
            const [shour, smin] = timeSlot.s_time.split(":");
            const [ehour, emin] = timeSlot.e_time.split(":");

            const sDate = this.dhm__dateUtilsLib.set(
              this.dhm__getTodayInUserTZ,
              {
                hours: shour,
                minutes: smin,
              }
            );
            const eDate = this.dhm__dateUtilsLib.set(
              this.dhm__getTodayInUserTZ,
              {
                hours: ehour,
                minutes: emin,
              }
            );

            let durationMins = this.dhm__dateUtilsLib.differenceInMinutes(
              eDate,
              sDate
            );
            if (shour === "23" && ehour === "00") {
              durationMins = sDate.diff(eDate, "minutes");
            }
            // console.log(s_time, e_time, durationMins)
            timeSlot.duration = durationMins;
            return timeSlot;
          }
        }
      );
    },
    ehm__error422Handler: function (err) {
      console.log(err.response.data);
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        err.response.data.errors.details.forEach((el, index) => {
          this.ehm__unhandledErrorMessage += el.message + ",";
        });
      } else {
        this.ehm__unhandledErrorMessage += err.response.data.message + ",";
      }
      this.ehm__unhandledErrorMessage = this.ehm__unhandledErrorMessage.trim();
    },
    setTimeRangeError(indexTimeSlot) {
      return `${this.$t(
        "given_schedule_overlapping_with_the_schedule"
      )} ${indexTimeSlot}`;
    },
    handleChangedTemplate: function (feedTemplate) {
      this.editedTemplate = feedTemplate
    }
  },
};
</script>
<style lang="scss">
.feed-template-action-dialog{
  &.hybrid-type {
    @include responsiveProperty(--dialog-width, 80%, 60%, 40%);
  }
  &.schedule-type {
    @include responsiveProperty(--dialog-width, 55%, 40%, 35%);
  }
}
</style>
