<template>
  <el-form :model="editableTemplate" size="mini" class="feed-table">
    <div style="display: flex; flex-direction: column">
      <el-form-item :label="$t('Comn_alias_name')" class="alias-name">
        <el-input
          :maxlength="fieldLengths.max_template_alias_name_length"
          :value="editableTemplate.title"
          :placeholder="$t('Comn_name')"
          @input="handleTitleChange"
        />
      </el-form-item>
      <table
        class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
      >
        <thead>
          <th class="is-leaf" style="width: 50px; text-align: center;">
            <!-- {{ tableRowsNames.particulars }} -->
              {{  `S #` }}
          </th>
          <th class="is-leaf">{{ tableRowsNames.s_time }}</th>
          <th class="is-leaf">{{ tableRowsNames.e_time }}</th>
          <th class="is-leaf" style="width: 95px;">{{ tableRowsNames.feed_percentage }}</th>
          <th class="is-leaf" style="width: 100px;">{{ tableRowsNames.actions }}</th>
        </thead>

        <tr v-for="(slot, index) in editableTemplate.timeSlots" :key="index">
          <th>
            <span
              style="color: #0a2463; font-weight: normal; padding-left: 4px"
              >{{ `S ${index + 1}` }}</span
            >
          </th>
          <td class="time-container">
            <el-form-item>
              <el-time-picker
                size="mini"
                v-model="slot.s_time"
                :picker-options="picker_options"
                value-format="HH:mm"
                format="HH:mm"
                :clearable="false"
                @change="handleTimeChange($event, index)"
              ></el-time-picker>
            </el-form-item>
          </td>
          <td class="time-container">
            <el-form-item>
              <el-time-picker
                size="mini"
                v-model="slot.e_time"
                :picker-options="picker_options"
                value-format="HH:mm"
                format="HH:mm"
                :clearable="false"
                @change="handleTimeChange($event, index)"
              ></el-time-picker>
            </el-form-item>
          </td>
          <td class="feed-percent-container">
            <el-form-item>
              <el-input
                type="number"
                :controls="false"
                :min="0"
                :max="100"
                v-model="slot.feed_percentage"
                @change="handleFeedChange($event, index)"
              ></el-input>
            </el-form-item>
          </td>
          <td class="actions-container">
            <er-button
              btnType="add"
              :showIcon="true"
              size="mini"
              :disabled="editableTemplate.timeSlots.length >= 8"
              @click="handleAddNewSample(index)"
            ></er-button>
            <er-button
              btnType="delete"
              :showIcon="true"
              size="mini"
              :disabled="editableTemplate.timeSlots.length < 2"
              @click="handleRemoveSample(index)"
            ></er-button>
          </td>
        </tr>
      </table>
    </div>
  </el-form>
</template>

<script>
import TimeSlot from "../../model/timeslot";
import errorHandlerMixin from "../../mixins/errorHandlerMixin";
import datesHandlerMixin from "../../mixins/adminDatesHandlerMixin";
import fieldLengths from "../../constants/fieldLengths";
export default {
  name: "ScheduleFeedTemplate",
  mixins: [errorHandlerMixin, datesHandlerMixin],
  props: {
    feedTemplate: {
      default: function () {
        return {}
      },
      required: true,
    },
    tabData: {
      default: {},
    },
    location: {
      default: {},
    },
    tempLoading: {
      default: true,
    },
  },
  data: function () {
    return {
      picker_options: {
        format: "HH:mm",
      },
      editableTemplate: {},
      fieldLengths
    };
  },
  computed: {
    tableRowsNames() {
      return {
        particulars: this.$t("Comn_schedules"),
        feed_percentage: this.$t("feed_ratio_%"),
        s_time: this.$t("PM_start_time"),
        e_time: this.$t("PM_end_time"),
        actions: this.$t("action")
      };
    },
  },
  watch: {
    feedTemplate: function() {
      this.editableTemplate = this.$lodash.cloneDeep(this.feedTemplate);
    }
  },
  mounted: function() {
    this.editableTemplate = this.$lodash.cloneDeep(this.feedTemplate);
  },
  methods: {
    handleAddNewSample: function (index) {
      if (this.editableTemplate.timeSlots.length >= 8) {
        return;
      }
      this.editableTemplate.timeSlots.splice(index + 1, 0, new TimeSlot());
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleRemoveSample: function (indexSample) {
      if (this.editableTemplate.timeSlots.length < 2) {
        return;
      }
      this.editableTemplate.timeSlots.splice(indexSample, 1);
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleTitleChange(templateTitle) {
      if (templateTitle.length > this.fieldLengths.max_template_alias_name_length) {
        return;
      }
      this.editableTemplate.title = templateTitle
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleTimeChange(timeSelected, indexTimeSlot) {
      const currTS = this.editableTemplate.timeSlots[indexTimeSlot];
      currTS.s_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.s_time);
      currTS.e_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.e_time);
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleFeedChange(feedValue, indexTimeSlot) {
      const currTS = this.editableTemplate.timeSlots[indexTimeSlot];
      if (feedValue === "") {
        currTS.feed_percentage = +feedValue;
      } else if (feedValue < 0) {
        currTS.feed_percentage = 0;
      } else if (feedValue > 100) {
        currTS.feed_percentage = 100;
      } else {
        currTS.feed_percentage = +feedValue;
      }
      this.$emit("changedTemplate", this.editableTemplate);
    },
  },
};
</script>

<style lang="scss">
.feed-table {
  --table-text-alignment: left;
  thead th{
    text-align: var(--table-text-alignment);
    padding: 0px 10px 5px 10px;
  }
  .el-input__inner {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }

  .alias-name {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    label {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      line-height: 20px;
      color: #0a2463;
    }
  }

  .el-form-item:not(.alias-name) {
    margin-bottom: 0px;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 105px;
  }

  .el-input__inner {
    text-align: var(--table-text-alignment);
  }

  .el-table th.is-leaf {
    border-bottom: 1px solid #ebeef5;
    font-weight: normal;
  }

  .el-table__append-wrapper {
    border-top: 0.5px solid #ebeef5;
  }

  .el-form-item__label {
    margin-left: 10px;
  }
  .el-table::before{
    height: 0px;
  }
}
</style>
