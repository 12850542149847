<template>
  <el-form :model="editableTemplate" size="mini" class="hybrid-feed-table">
    <div style="display: flex; flex-direction: column">
      <el-form-item :label="$t('Comn_alias_name')" class="alias-name">
        <el-input
          :maxlength="fieldLengths.max_template_alias_name_length"
          :value="editableTemplate.title"
          :placeholder="$t('Comn_name')"
          @input="handleTitleChange"
        />
      </el-form-item>
      <table
        class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
      >
        <thead>
          <th class="is-leaf" style="width: 50px; text-align: center;">
            <!-- {{ tableRowsNames.particulars }} -->
              {{ `S #`}}
          </th>
          <th class="is-leaf">{{ tableRowsNames.mode }}</th>
          <th class="is-leaf" style="width: 95px">{{ tableRowsNames.s_time }}</th>
          <th class="is-leaf" style="width: 95px">{{ tableRowsNames.e_time }}</th>
          <th class="is-leaf" style="width: 50px">
            {{ tableRowsNames.feed_percentage }}
          </th>
          <th class="is-leaf">{{ tableRowsNames.feeding_level }}</th>
          <th class="is-leaf" style="width: 100px">
            {{ tableRowsNames.actions }}
          </th>
        </thead>

        <tr v-for="(slot, timeSlotIndex) in editableTemplate.timeSlots" :key="timeSlotIndex">
          <th>
            {{ `S ${timeSlotIndex + 1}` }}
          </th>
          <td class="mode-container">
            <el-form-item>
              <er-select
                class="mode_dropdown"
                :value="slot.mode"
                :placeholder="$t('select_mode')"
                size="mini"
                popper-class="mode-dropdown"
                :popper-append-to-body="false"
                @change="(event) => handleModeChange(event, timeSlotIndex)"
              >
                <template slot="prefix">
                  <svg
                    class="list-item__color-swatch_pm"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3"
                      :fill="getTSColor(slot)"
                    ></circle>
                    <title>{{ getTSMode(slot).translation }}</title>
                  </svg>
                </template>
                <el-option
                  v-for="value in availableTSModes"
                  :label="$t(value.lang_key)"
                  :key="value.mode"
                  :value="value.mode"
                >
                  <svg
                    v-if="value.mode !== 'HYBRID'"
                    class="list-item__color-swatch_pm"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3"
                      :fill="getTSColor(value)"
                    ></circle>
                    <title>{{ getTSMode(value).translation }}</title>
                  </svg>
                  <span class="option-mode">{{ $t(value.lang_key) }}</span>
                </el-option>
              </er-select>
            </el-form-item>
          </td>
          <td class="time-container">
            <el-form-item>
              <el-time-picker
                size="mini"
                v-model="slot.s_time"
                :picker-options="picker_options"
                value-format="HH:mm"
                format="HH:mm"
                :clearable="false"
                @change="handleTimeChange($event, timeSlotIndex)"
              ></el-time-picker>
            </el-form-item>
          </td>
          <td class="time-container">
            <el-form-item>
              <el-time-picker
                size="mini"
                v-model="slot.e_time"
                :picker-options="picker_options"
                value-format="HH:mm"
                format="HH:mm"
                :clearable="false"
                @change="handleTimeChange($event, timeSlotIndex)"
              ></el-time-picker>
            </el-form-item>
          </td>
          <td class="feed-percent-container">
            <el-form-item>
              <el-input
                type="number"
                :controls="false"
                :min="0"
                :max="100"
                v-model="slot.feed_percentage"
                @change="handleFeedChange($event, timeSlotIndex)"
              ></el-input>
            </el-form-item>
          </td>
          <td class="mode-container">
            <el-form-item>
              <er-select
                class="mode_dropdown_alignment_schedules"
                :value="slot.feeding_level"
                :placeholder="$t('Comn_feed_level')"
                size="mini"
                value-key="value"
                popper-class="mode-dropdown"
                :disabled="disableFeedingLevel(slot)"
                @change="handleFeedingLevelChange($event, timeSlotIndex)"
              >
                <el-option
                  v-for="item in feedingLevelOptions(slot)"
                  :label="$t(item.label)"
                  :key="item.value"
                  :value="item.value"
                  :disabled="isFeedingLevelOptionAvailable(item.value, timeSlotIndex)"
                ></el-option>
              </er-select>
            </el-form-item>
          </td>
          <td class="actions-container">
            <er-button
              btnType="add"
              :showIcon="true"
              size="mini"
              :disabled="editableTemplate.timeSlots.length >= 8"
              @click="handleAddNewSample(timeSlotIndex)"
            ></er-button>
            <er-button
              btnType="delete"
              :showIcon="true"
              size="mini"
              :disabled="editableTemplate.timeSlots.length < 2"
              @click="handleRemoveSample(timeSlotIndex)"
            ></er-button>
          </td>
        </tr>
      </table>
    </div>
  </el-form>
</template>

<script>
import TimeSlot from "../../model/timeslot";
import errorHandlerMixin from "../../mixins/errorHandlerMixin";
import datesHandlerMixin from "../../mixins/adminDatesHandlerMixin";
import { generateColorForMode } from "../../utils/componentColors";
import fieldLengths from "../../constants/fieldLengths";
export default {
  name: "HybridFeedTemplate",
  mixins: [errorHandlerMixin, datesHandlerMixin],
  props: {
    feedTemplate: {
      default: function () {
        return {};
      },
      required: true,
    },
    tabData: {
      default: {},
    },
    location: {
      default: {},
    },
    tempLoading: {
      default: true,
    },
  },
  data: function () {
    return {
      picker_options: {
        format: "HH:mm",
      },
      editableTemplate: {},
      feedingLevel: [
        {
          label: "Comn_na",
          value: -1
        },
        {
          label: "Comn_normal",
          value: 0,
        },
        {
          label: "Comn_economical",
          value: 1,
        },
        {
          label: "Comn_aggressive",
          value: 2,
        },
      ],
      modeToFeedingLevels: {
        SCHEDULE: new Set([-1]),
        AUTOMATIC: new Set([0, 1, 2])
      },
      fieldLengths
    };
  },
  computed: {
    tableRowsNames() {
      return {
        particulars: this.$t("Comn_schedules"),
        mode: this.$t("mode"),
        s_time: this.$t("PM_start_time"),
        e_time: this.$t("PM_end_time"),
        feed_percentage: this.$t("feed_ratio_%"),
        feeding_level: this.$t("Comn_feeding_level"),
        actions: this.$t("action"),
      };
    },
    availableTSModes() {
      return {
        SHRIMP_TALK: { mode: "AUTOMATIC", lang_key: "PM_automatic" },
        FARMER: { mode: "SCHEDULE", lang_key: "Comn_schedule" },
      };
    },
  },
  watch: {
    feedTemplate: function () {
      this.editableTemplate = this.$lodash.cloneDeep(this.feedTemplate);
    },
  },
  mounted: function () {
    this.editableTemplate = this.$lodash.cloneDeep(this.feedTemplate);
  },
  methods: {
    isFeedingLevelOptionAvailable: function(feedingLevelOption, timeSlotIndex) {
      const mode = this.editableTemplate.timeSlots[timeSlotIndex].mode
      return !this.modeToFeedingLevels[mode].has(feedingLevelOption)
    },
    feedingLevelOptions: function(timeSlot) {
      return this.feedingLevel.filter(x => this.modeToFeedingLevels[timeSlot.mode].has(x.value))
    },
    getTSMode(timeSlot) {
      const mode = timeSlot.mode;
      return generateColorForMode(mode);
    },
    disableFeedingLevel: function (slot) {
      return slot.mode === "SCHEDULE";
    },
    getTSColor: function (timeSlot) {
      return this.getTSMode(timeSlot).color;
    },
    handleAddNewSample: function (index) {
      if (this.editableTemplate.timeSlots.length >= 8) {
        return;
      }
      this.editableTemplate.timeSlots.splice(index + 1, 0, new TimeSlot());
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleRemoveSample: function (indexSample) {
      if (this.editableTemplate.timeSlots.length < 2) {
        return;
      }
      this.editableTemplate.timeSlots.splice(indexSample, 1);
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleTitleChange(templateTitle) {
      if (this.editableTemplate.title.length > this.fieldLengths.max_template_alias_name_length) {
        return;
      }
      this.editableTemplate.title = templateTitle
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleTimeChange(timeSelected, indexTimeSlot) {
      const currTS = this.editableTemplate.timeSlots[indexTimeSlot];
      currTS.s_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.s_time);
      currTS.e_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.e_time);
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleFeedChange(feedValue, indexTimeSlot) {
      const currTS = this.editableTemplate.timeSlots[indexTimeSlot];
      if (feedValue === "") {
        currTS.feed_percentage = +feedValue;
      } else if (feedValue < 0) {
        currTS.feed_percentage = 0;
      } else if (feedValue > 100) {
        currTS.feed_percentage = 100;
      } else {
        currTS.feed_percentage = +feedValue;
      }
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleModeChange(mode, timeSlotIndex) {
      this.editableTemplate.timeSlots[timeSlotIndex].mode = mode;
      if (mode === "SCHEDULE") {
        this.handleFeedingLevelChange(-1, timeSlotIndex)
      } else {
        this.handleFeedingLevelChange(0, timeSlotIndex)
      }
      this.$emit("changedTemplate", this.editableTemplate);
    },
    handleFeedingLevelChange(feeding_level, timeSlotIndex) {
      this.editableTemplate.timeSlots[timeSlotIndex].feeding_level = feeding_level;
      this.$emit("changedTemplate", this.editableTemplate);
    }
  },
};
</script>

<style lang="scss">
.hybrid-feed-table {
  --table-text-alignment: left;

  thead th {
    text-align: var(--table-text-alignment);
    padding: 0px 10px 5px 10px;
  }

  .el-input__inner {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }

  .list-item__color-swatch_pm {
    height: 15px;
    width: 15px;

    & + .option-mode {
      margin-left: 5px;
    }
  }

  .alias-name {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    label {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      line-height: 20px;
      color: #0a2463;
    }
  }

  .mode-container {
    .el-input__prefix {
      line-height: 27px;
      display: flex;
      align-items: center;
    }
  }

  .el-form-item:not(.alias-name) {
    margin-bottom: 0px;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 105px;
  }

  .el-input__inner {
    text-align: var(--table-text-alignment);
  }

  .el-table th.is-leaf {
    border-bottom: 1px solid #ebeef5;
    font-weight: normal;
  }

  .el-table__append-wrapper {
    border-top: 0.5px solid #ebeef5;
  }

  .el-form-item__label {
    margin-left: 10px;
  }

  .el-table::before {
    height: 0px;
  }
}
</style>
